@use '../../scss/' as *;

.flat-accordion {
    max-width: 740px;
    margin: 0 auto;
    .accordion-item {
        margin-bottom: 21px;
        border-radius: 0;
        background: rgba(34, 183, 143, 0.1);
        color: #fff;
        box-shadow: none;

        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
        border: 2px solid $bg-2;
        position: relative;
        z-index: 1;
        padding: 29px 23px 29px 19px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }

        &::before, &::after {
            @include transition3;
            content: '';
            position: absolute;
            background: #300e0e;
            z-index: -1;
        }

        &::before {
            width: calc(102% - 3rem);
            height: calc(100% + 4px);
            top: -2px;
            left: 50%;
            transform: translateX(-50%);
        }
        &::after {
            height: calc(110% - 3rem);
            width: calc(100% + 4px);
            left: -2px;
            top: 50%;
            transform: translateY(-50%);
        }

        &.active,
        &:hover {
        &::before {
            width: 0 !important;
        }
        &::after {
            height: 0 !important; 
        }
            border-color: $bg-2;
            box-shadow: $box-shadow;
        }

        

        .accordion-button {
            position: relative;
            background: transparent;
            transform: translateY(0);
            padding: 0;
            transition: all 0.3s ease;
            
            position: relative;
            z-index: 1;
            border-radius: 0 !important;
            color: #fff;
            box-shadow: none;
            font-size: 24px;
            line-height: 1.33;
            font-family: "Chakra Petch", sans-serif;
            font-weight: 700;
            text-transform: capitalize;

            border-bottom: 1px solid rgba(3, 253, 203, 0.1);
            padding-bottom: 15px;

            margin-bottom: 16px;

            &::after {
                background-image: none;
                content: "\f078";
                font-family: "Font Awesome 5 Pro";
                font-weight: 500;
                font-size: 25px;
                color: $bg-2;
                position: absolute;
                top: 0;
                right: 0;
                width: auto;
                height: auto;
            }

            &.collapsed {
                border-bottom: 1px solid rgba(3, 253, 203, 0);
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }


        .accordion-collapse {
            background: transparent;

            .accordion-body {
                padding: 0
            }
        }
        
        &.corner-box {
            @include transition3;
            padding: 28px 18px 11px;
            .toggle-title {
                @include transition3;
                border-bottom: 1px solid rgba(3, 253, 203, 0);
                padding-bottom: 16px;
                margin-bottom: 0;
                position: relative;
                padding-right: 30px;
                &::after {
                    @include  transition3;
                    position: absolute;
                    right: 4px;
                    top: 0px;
                    content    : "\f078";
                    font-family: "Font Awesome 5 Pro";
                    font-weight: 500;
                    font-size: 25px;
                    color: $bg-2;
                }
                &:hover {
                    cursor: context-menu;
                }
            }
        }
        &.active {
            &.corner-box {
                padding: 28px 18px;
                .toggle-title {
                    margin-bottom: 16px;
                    border-bottom: 1px solid rgba(3, 253, 203, 0.1);
                    &::after {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }
}
